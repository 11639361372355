import React, { useEffect, useRef } from 'react'

import SEO from '~components/SEO'
import MainHeader from '~components/MainHeader'
import MainFooter from '~components/MainFooter'
import { MastheadHome } from '~components/Masthead'
import TextBlock from '~components/TextBlock'
import ClientsBlock from './components/ClientsBlock'
import ServicesBlock from './components/ServicesBlock'
import ImpactBlock from './components/ImpactBlock'
import MailingListBlock from './components/MailingListBlock'
import TeamBlock from './components/TeamBlock'

import { updateBackgroundScroll } from '~utils/scroll-session'

import { Home } from './style'

import { useCraft } from './query'

const HomeTemplate = ({ isBackground }) => {
  /*
   * The home page must use a static query
   * as it will be rendered as a background
   * page in the layout component.
   */
  const { entry } = useCraft()
  const node = useRef(null)

  useEffect(() => {
    if (isBackground) {
      updateBackgroundScroll(node, { pathname: '/' })
    }
  }, [])

  return (
    <Home ref={node} aria-hidden={isBackground}>
      {!isBackground &&
        <SEO
          title={entry.masthead.heading}
          metadata={entry.metadata}
        />
      }
      <MainHeader />
      <MastheadHome
        {...entry.masthead}
      />
      {entry.introBlocks.map((block, i) =>
        <TextBlock key={i} {...block} collapseMobile />
      )}
      <ServicesBlock collapseMobile />
      <ImpactBlock
        impactStatement={entry.impactStatement}
        impactImage={entry.impactImage}
        bcorpStatement={entry.bcorpStatement}
        collapseMobile
      />
      <TeamBlock collapseMobile />
      <ClientsBlock collapseMobile />
      <MailingListBlock
        text={entry.mailingList}
        collapseMobile
      />
      <MainFooter />
    </Home>
  )
}

export default HomeTemplate
