import React from 'react'
import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'
import styled from 'styled-components'

const MastheadImage = styled.img`
  width: 100%;
  display: block;
`

const MastheadStaffMember = ({
  heading,
  backgroundColor,
  image
}) => {
  const color = backgroundColor === 'black' ? 'white' : 'black'

  return (
    <Section
      color={color}
      bg={backgroundColor}
    >
      <Container collapseBottomPadding={true}>
        <Grid>
          <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }}>
            <h1>{heading}</h1>
          </Cell>
          <Cell gridColumn={{ _: 'span 5', sm: '7 / span 4', lg: '9 / span 4' }}>
            {image &&
              <MastheadImage
                src={image.url}
                alt={image.title}
              />
            }
          </Cell>
        </Grid>
      </Container>
    </Section>
  )
}

export default MastheadStaffMember
