import React from 'react'

import {
  ParallaxWrapper,
  ParallaxFixed,
  ParallaxClone
} from './style'

import useBreakpoint from '~utils/use-breakpoint'

const Parallax = ({ children, ...props }) => {
  const { sm } = useBreakpoint()

  if (sm) {
    return (
      <ParallaxWrapper>
        <ParallaxFixed
          {...props}
        >
          {children}
        </ParallaxFixed>
        <ParallaxClone
          aria-hidden="true"
        >
          {children}
        </ParallaxClone>
      </ParallaxWrapper>
    )
  }

  return children
}

Parallax.defaultProps = {
  top: 0,
  left: 0,
  right: 0
}

export default Parallax
