import styled from 'styled-components'

export const ExpandButton = styled.button`
  padding: 20px;
  width: 100%;
  overflow: visible;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0;
    max-width: calc(100% - 35px);
  }
`

export const ExpandButtonIcon = styled.i`
  width: 15px;
  height: 15px;
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: currentColor;
  }

  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: currentColor;
    display: ${props => props.isOpen ? 'none' : 'block'};
  }
`