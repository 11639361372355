import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    craft {
      crafts: categories(group: crafts) {
        id
        title
      }
      staffMembers: entries(section: staffMembers) {
        ...staffMemberFields
      }
    }
  }
`

export const useCraft = () => {
  return useStaticQuery(query).craft
}
