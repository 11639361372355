import React from 'react'
import PropTypes from 'prop-types'
import { AnchorLink, GatsbyLink, ButtonLink } from './style'

const Link = ({
  to,
  children,
  underlined,
  hoverless,
  active,
  activeColor,
  ...otherProps
}) => {

  const props = {
    ...otherProps,
    active,
    activeColor,
    underlined: underlined,
    hoverless: hoverless || active
  }

  if (!to) {
    return (
      <ButtonLink {...props}><span>{children}</span></ButtonLink>
    )
  }

  if (to && to.startsWith('/')) {
    return (
      <GatsbyLink to={to} {...props}>{children}</GatsbyLink>
    )
  }

  return (
    <AnchorLink href={to} {...props}>{children}</AnchorLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  active: PropTypes.bool,
  activeColor: PropTypes.string,
  underlined: PropTypes.bool,
  hoverless: PropTypes.bool
}

export default Link
