import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
{
  craft {
    globals {
      companyDetails {
        contactEmail
        contactNumber
        officeAddress
      }
      acknowledgements {
        acknowledgementOfCountry
        equalityStatement
      }
    }
  }
}
`

export const useCraft = () => {
  return useStaticQuery(query).craft
}