import React from "react"

import { Cell, Box } from "~styles/common"
import Block from "~components/Block"

const TextBlock = ({
  heading,
  body,
  image,
  fontSize,
  hidePaddingCell,
  backgroundColor,
  ...props
}) => {
  const bodyTag = ["div", "h2"][fontSize]

  return (
    <Block bg={backgroundColor} heading={heading} image={image} {...props}>
      <Cell gridColumn={{ _: "span 5", sm: "6 / span 5", lg: "8 / span 5" }}>
        <Box as={bodyTag} dangerouslySetInnerHTML={{ __html: body }} />
      </Cell>
      {!hidePaddingCell && <Cell />}
    </Block>
  )
}

TextBlock.defaultProps = {
  fontSize: 1,
}

export default TextBlock
