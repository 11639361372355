import React from 'react'
import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'

const getBackground = ({ image, color }) => {
  if (image) {
    if (color === 'black') {
      return `
        linear-gradient(
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0.8)
        ),
        url(${image.url})
      `
    }
    return `url(${image.url})`
  }

  return color
}

const MastheadDefault = ({
  heading,
  subheading,
  backgroundColor,
  image
}) => {
  const color = backgroundColor === 'black' ? 'white' : 'black'
  const background = getBackground({ image, color: backgroundColor })

  return (
    <Section
      color={color}
      background={background}
      backgroundSize="cover"
      backgroundPosition="center left"
    >
      <Container collapseBottomPadding>
        <Grid>
          <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }}>
            <h1>{heading}</h1>
          </Cell>
          <Cell display={{ sm: 'none' }} />
          <Cell display={{ sm: 'none' }} />
          <Cell gridColumn={{ _: 'span 5', sm: '7 / span 4', lg: '9 / span 4' }}>
            <h2 dangerouslySetInnerHTML={{ __html: subheading }} />
          </Cell>
          <Cell display={{ _: 'none', sm: 'block' }} />
          <Cell display={{ _: 'none', sm: 'block' }} />
        </Grid>
      </Container>
    </Section>
  )
}

export default MastheadDefault
