import { flattenContent, staticSuperTable } from '~utils/mapping-filters'

const mapEntry = (entry) => {
  const masthead = staticSuperTable(entry.masthead)
  const metadata = staticSuperTable(entry.metadata)

  return {
    ...entry,
    masthead: {
      ...flattenContent(masthead, 'subheading'),
      image: masthead.image[0]
    },
    metadata
  }
}

export const mapWorkItem = (workItem) => {
  const {
    client,
    impacts,
    services
  } = workItem

  const caseStudy = workItem.caseStudy[0]
  
  return {
    ...workItem,
    client: client[0],
    impact: impacts[0],
    service: services[0],
    caseStudy: caseStudy ? {
      ...caseStudy,
      image: caseStudy.featureImage[0]
    } : null
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    entry: mapEntry(craft.entry)
  }
}