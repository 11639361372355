// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-studies-index-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/templates/case-studies/index.js" /* webpackChunkName: "component---src-templates-case-studies-index-js" */),
  "component---src-templates-staff-members-index-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/templates/staff-members/index.js" /* webpackChunkName: "component---src-templates-staff-members-index-js" */),
  "component---src-templates-pages-index-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-careers-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("/Users/kolber/Sites/_github/today/today-site/frontend/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

