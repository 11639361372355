import styled from 'styled-components'
import posed from 'react-pose'
import theme from '~styles/theme'
import bgImage from '~assets/images/v-strip.jpg'

const duration = 200

const FadeCloseLayer = posed.div({
  enter: {
    opacity: 0.5,
    transition: {
      opacity: {
        duration: 0
      }
    }
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        duration: 0
      }
    }
  }
})

const SlidingLayer = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    delayChildren: duration,
    transition: {
      default: ({ isBackground }) => isBackground ? { duration: 0 } : { ease: 'easeOut', duration },
      opacity: {
        duration: 100
      },
    }
  },
  exit: {
    x: ({ isBackground }) => isBackground ? 0 : '180px',
    opacity: ({ isBackground }) => isBackground ? 1 : 0,
    transition: {
      default: ({ isBackground }) => isBackground ? { duration: 0 } : { ease: 'easeOut', duration },
      opacity: {
        delay: duration * 0.5,
        duration: 0
      },
    },
    applyAtStart: { top: ({ scrollTop }) => `${scrollTop * -1}px` },
    applyAtEnd: { top: 0 }
  }
})

export const CloseLayer = styled(FadeCloseLayer)`
  position: fixed;
  left: 0;
  right: 0;
  background-image: url('${bgImage}');
  cursor: pointer;
  height: 100%;
  opacity: 0.6;
  z-index: ${({ isBackground, level }) => isBackground ? (level * 100) + 9 : (level * 99)};
`

export const Layer = styled(SlidingLayer)`
  position: ${({ isBackground }) => isBackground ? 'fixed' : 'absolute'};
  left: ${({ level }) => `${level * 60}px`};
  top: 0;
  right: 0;
  bottom: 0;
  /* When navigating back from a 3rd level page to a 2nd level page,
  the 2nd layer exist on both the entering and exiting pages. 
  The entering layer is animated, while the exiting layer is static (isBackground).
  The static layer should appear on top to hide a flicker that occurs. */
  z-index: ${({ isBackground, level }) => isBackground ? (level * 100) + 10 : (level * 100)};
  pointer-events: ${({ isBackground }) => isBackground ? 'none' : 'auto'};
  background-color: white;
  cursor: ${({ isBackground }) => isBackground ? 'pointer' : 'auto'};
  min-height: 101vh;

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    left: ${({ level }) => `${level * 90}px`};
  }

  ${({ miniLayer }) => miniLayer ? `left: auto !important; width: 710px;` : ''}
`
