import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'

import MainHeader from '~components/MainHeader'
import MainFooter from '~components/MainFooter'

import { updateBackgroundScroll } from '~utils/scroll-session'
import useBreakpoint from '~utils/use-breakpoint'

import { Layer, CloseLayer } from './style'

const PageLayer = ({
  location,
  level,
  backTo,
  children,
  isBackground,
  miniLayer
}) => {
  const { sm } = useBreakpoint()
  const node = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)

  const handleCloseClick = () => navigate(backTo)

  const updateScrollTop = () => {
    setScrollTop(window.scrollY)
  }

  useEffect(() => {
    if (isBackground && location) {
      updateBackgroundScroll(node, location)
    }
    if (!isBackground) {
      setScrollTop(window.scrollY)
      window.addEventListener('scroll', updateScrollTop)
    }
    return () => {
      window.removeEventListener('scroll', updateScrollTop)
    }
  }, [])

  if (sm) {
    return (
      <>
        <Layer
          ref={node}
          level={level}
          scrollTop={scrollTop}
          miniLayer={miniLayer}
          isBackground={isBackground}
          aria-hidden={isBackground}
        >
          {children}
        </Layer>
        <CloseLayer
          onClick={handleCloseClick}
          level={level}
          isBackground={isBackground}
        />
      </>
    )
  }

  return (
    <div ref={node}>
      <MainHeader />
      {children}
      <MainFooter />
    </div>
  )
}

PageLayer.defaultProps = {
  level: 1,
  backTo: '/'
}

export default PageLayer
