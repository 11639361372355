import React from 'react'
import { Box } from '~styles/common'

const TodayLogo = (props) => {
  return (
    <Box
      as="svg"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 442.94 160">
      <title>Today</title>
      <path fill="#fff" d="M38.79 116.72a23.89 23.89 0 0 0 3.59-12.93V6.64H31.25q-10.42 0-17.51 6T.36 32.68H0L2.87 0h107L113 32.68h-.36q-6.29-14-13.38-20t-17.69-6h-11v97.15a23.89 23.89 0 0 0 3.59 12.93 22.53 22.53 0 0 0 9.7 8.62v.36H28.91v-.36a22.32 22.32 0 0 0 9.88-8.66z"/>
      <path fill="#fff" d="M101.08 106.67q-6.82-10.59-6.82-25.5 0-14.72 6.37-25.32a43.14 43.14 0 0 1 16.43-16.07 42.68 42.68 0 0 1 20.65-5.48 45.23 45.23 0 0 1 37.17 20.92q6.82 10.51 6.82 25.59 0 14.72-6.37 25.41a42.3 42.3 0 0 1-16.43 16.07 42 42 0 0 1-40.85.18 44.06 44.06 0 0 1-16.97-15.8zm52.26 1.71a70.78 70.78 0 0 0 1.8-17.15A101.74 101.74 0 0 0 152 65.27q-3.14-11.94-8.26-18.95t-10.51-7q-3.05 0-5.93 3.59t-4.67 10.69a70.78 70.78 0 0 0-1.8 17.15A100.7 100.7 0 0 0 124 96.61q3.14 11.85 8.26 18.86t10.51 7q3.05 0 5.93-3.5t4.64-10.6zm123.27 16.96v.36H235q-20.29 0-32.5-10.6t-12.26-31.6q0-16 6.91-26.67A42.53 42.53 0 0 1 214.93 41a52.3 52.3 0 0 1 22.54-5.12h4.13v-14q0-8.62-1.62-13.47t-7.9-8.05V0h35v103.79q0 8.62 1.62 13.47t7.91 8.08zm-35-84.22h-5a23.41 23.41 0 0 0-10.68 2.15q-4.22 2.16-6.82 8.53t-2.6 18.59a88.72 88.72 0 0 0 3.41 25.32q3.41 11.49 9.07 18.14t12.3 6.64h.36zM362 125.34v.36h-34.67v-10.05q-13.11 11-23.52 11a22.33 22.33 0 0 1-12.66-3.41 21.84 21.84 0 0 1-7.63-8.44 22.66 22.66 0 0 1-2.52-10.29 24.12 24.12 0 0 1 2.51-11.4 25.7 25.7 0 0 1 7.36-8.44A130.39 130.39 0 0 1 304.52 76q9-5.21 13.56-8.44a27.26 27.26 0 0 0 7-6.82 14.42 14.42 0 0 0 2.42-8.26 12.47 12.47 0 0 0-2.6-8.08 9 9 0 0 0-7.45-3.23q-4.67 0-11 3.5T290 54.77L285.13 58l-.36-.36 14.9-19.93a171.61 171.61 0 0 1 29.09-3.05q12.39 0 18 5.84t5.66 15.17v48.13q0 8.62 1.62 13.47t7.96 8.07zm-34.66-23.7V67.16q-10.59 8.44-14.55 12.3a26.53 26.53 0 0 0-5.84 8.08q-1.89 4.22-1.89 11.22a17.62 17.62 0 0 0 3.68 11.4q3.68 4.58 8.53 4.58a8.94 8.94 0 0 0 7.27-3.41q2.79-3.41 2.79-9.69zm115.6-65.37a20.16 20.16 0 0 0-4.67 6.64q-2.15 4.49-5.75 13.83L406.67 123a141.37 141.37 0 0 1-10 21.37q-4.76 7.9-10.68 11.76a25.75 25.75 0 0 1-14.34 3.87 21.42 21.42 0 0 1-4.67-.36l-1.8-30.71h.36q7 7.9 16.88 7.9a17.58 17.58 0 0 0 11-3.41q4.49-3.41 7.72-11.67l-26.61-63.93q-3.59-8.8-6.46-14.28t-5.21-7.27v-.36H406v.36q-8.8 2.87-8.8 9.52 0 3.41 2.33 9.34l13.83 35.74 12.93-35q2.33-6.47 2.33-10.06 0-6.82-8.26-9.52v-.36h22.63z"/>
    </Box>
  )
}

export default TodayLogo
