import React, { useState } from 'react'
import {
  Section,
  Container,
  Grid,
  Cell,
  Box
} from '~styles/common'
import { ExpandButton, ExpandButtonIcon } from './style'

import useBreakpoint from '~utils/use-breakpoint'

const Block = ({ heading, headingGridColumn, stickyHeading, mobileOpenInitially, children, mobileOnlyHeading, collapseMobile, image, bg, ...props }) => {
  const [mobileOpen, setMobileOpen] = useState(mobileOpenInitially)
  const { sm } = useBreakpoint()
  const color = bg === 'black' ? 'white' : 'black'

  const toggleMobileOpen = () => {
    setMobileOpen(!mobileOpen)
  }

  // Render mobile collapsable
  if (!sm && collapseMobile) {
    const borderTopColor = bg === 'white' ? 'neutral' : 'transparent'

    return (
      <Box
        bg={bg}
        color={color}
        position="relative"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor={borderTopColor}
        {...props}
      >
        <ExpandButton onClick={toggleMobileOpen}>
          <h2>{heading}</h2>
          <ExpandButtonIcon isOpen={mobileOpen} />
        </ExpandButton>
        {mobileOpen &&
          <Section>
            <Container>
              <Grid>
                {children}
              </Grid>
            </Container>
          </Section>
        }
      </Box>
    )
  }

  return (
    <Section
      bg={bg}
      color={color}
      {...props}
    >
      <Container collapseBottomPadding={props.collapseBottomPadding}>
        <Grid>
          {image &&
            <Cell>
              <img
                style={{ display: 'block' }}
                src={image.url}
                alt={image.title}
                width="100%"
              />
            </Cell>
          }
          {heading && !mobileOnlyHeading &&
            <Cell stickyHeading={stickyHeading} gridColumn={headingGridColumn}>
              <h2>{heading}</h2>
            </Cell>
          }
          {children}
        </Grid>
      </Container>
    </Section>
  )
}

Block.defaultProps = {
  bg: 'white',
  headingGridColumn: 'span 4'
}

export default Block
