const sessionKey = 'page-scroll-session'

export const startScrollSession = () => {
  window.sessionStorage.setItem(sessionKey, '{}')
}

export const saveScrollPosition = (path, scrollPosition) => {
  const nextScrollSession = {
    [path]: scrollPosition
  }

  window.sessionStorage.setItem(
    sessionKey,
    JSON.stringify(nextScrollSession)
  )
}

export const getScrollPosition = (path) => {
  const scrollSession = window.sessionStorage.getItem(sessionKey) || {}
  const scrollPosition = JSON.parse(scrollSession)[path]
  return scrollPosition || [0, 0]
}

export const updateBackgroundScroll = (node, location) => {
  const [_, scrollTop] = getScrollPosition(location.pathname)
  node.current.style.top = `${scrollTop * -1}px`
  setTimeout(() => {
    if (!node.current) return
    node.current.style.transition = `top 800ms ease-in-out`
    node.current.style.top = `0px`
  }, 200)
}
