import React from 'react'

import { FlagHolder } from './style'
import { Cell } from '~styles/common'
import Link from '~components/Link'
import Block from '~components/Block'

import { breakLines } from '~utils/helpers'

import { useCraft } from './query'

const MainFooter = () => {
  const { companyDetails, acknowledgements } = useCraft().globals

  return (
    <Block
      as="footer"
      bg="black"
      color="white"
      heading="Contact us"
      collapseBottomPadding={true}
      zIndex="998"
    >
      <Cell gridColumn={{ _: 'span 3', lg: '8 / span 2' }}>
        <p>
          <Link underlined to={`mailto:${companyDetails.contactEmail}`}>
            {companyDetails.contactEmail}
          </Link>
          <br/>
          {companyDetails.contactNumber}
        </p>
        <p>{breakLines(companyDetails.officeAddress)}</p>
        <p><Link underlined to="https://g.page/todaydes?share">Google map</Link></p>
      </Cell>
      <Cell gridColumn={{ _: 'span 2', lg: '11 / span 2' }}>
        <p><Link underlined to="https://www.linkedin.com/company/todaydes/">LinkedIn</Link></p>
        <p><Link underlined to="https://www.instagram.com/todaydes/">Instagram</Link></p>
        <p><Link underlined to="https://twitter.com/todaydes">Twitter</Link></p>
      </Cell>
      <Cell />
      <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }}>
        <FlagHolder>
          <img
            height="45"
            src="https://upload.wikimedia.org/wikipedia/en/3/3f/Australian_Aboriginal_Flag.svg"
            alt="Aboriginal Flag"
          />
        </FlagHolder>
        <span
          dangerouslySetInnerHTML={{ __html: acknowledgements.acknowledgementOfCountry }}
        />
      </Cell>
      <Cell display={{ _: 'block', sm: 'none' }} />
      <Cell gridColumn={{ _: 'span 5', lg: '8 / span 4' }}>
        <FlagHolder>
          <img
            height="45"
            src="https://upload.wikimedia.org/wikipedia/commons/4/48/Gay_Pride_Flag.svg"
            alt="Gay Pride Flag"
          />
          <img
            height="45"
            src="https://upload.wikimedia.org/wikipedia/commons/b/b0/Transgender_Pride_flag.svg"
            alt="Transgender Pride Flag"
          />
        </FlagHolder>
        {acknowledgements.equalityStatement}
      </Cell>
      <Cell minHeight="20px" />
    </Block>
  )
}

export default MainFooter
