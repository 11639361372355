import React, { useState } from 'react'
import { breakLines } from '~utils/helpers'
import useInterval from '~utils/use-interval'
import {
  Section,
  Container,
  Grid,
  Cell,
  Box
} from '~styles/common'

import { HomeImageWrapper, HomeImage } from './style'

const MastheadHome = ({
  heading,
  subheading,
  images
}) => {
  const [imageIndex, setImageIndex] = useState(0)

  useInterval(() => {
    setImageIndex(current => current < images.length - 1 ? current + 1 : 0)
  }, 6000)

  return (
    <Section bg="white">
      <Container>
        <Box position="relative">
          <Grid position="relative" zIndex="10">
            <Cell gridColumn={{ _: 'span 3', sm: 'span 5' }}>
              <h1>{breakLines(heading)}</h1>
            </Cell>
            <Cell />
            <Cell />
            <Cell />
            <Cell display={{ _: 'block', sm: 'none' }} />
            <Cell display={{ _: 'block', sm: 'none' }} />
            <Cell display={{ _: 'none', sm: 'block' }} gridColumn="span 5">
              <h2 dangerouslySetInnerHTML={{ __html: subheading }} />
            </Cell>
          </Grid>
          {images && images.length > 0 &&
            <HomeImageWrapper>
              <Grid height="100%">
                <Cell
                  gridColumn={{ _: '2 / span 4', sm: '6 / span 5', lg: '8 / span 5' }}
                  marginRight={{ _: '-20px', sm: '-30px', lg: '-60px' }}
                  marginTop="-20px"
                  position="relative"
                >
                  {images.map((image, i) =>
                    <HomeImage
                      key={i}
                      opacity={imageIndex === i ? 1 : 0}
                      role="img"
                      aria-label={image.title}
                      backgroundImage={`url(${image.url})`}
                    />
                  )}
                </Cell>
              </Grid>
            </HomeImageWrapper>
          }
        </Box>
        <Grid>
          <Cell
            display={{ _: 'block', sm: 'none' }}
            gridColumn="span 5"
            marginTop="20px"
          >
            <h2 dangerouslySetInnerHTML={{ __html: subheading }} />
          </Cell>
          <Cell bg="white" />
        </Grid>
      </Container>
    </Section>
  )
}

export default MastheadHome