import React, { useState } from 'react'

import Link from '~components/Link'

import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'
import {
  FilterOption,
  MobileArchiveToggle,
  MobileArchiveContainer,
  MobileArchiveHeader
} from './style'
import { ExpandButtonIcon } from '~components/Block/style'

const Filter = ({
  backgroundColor,
  label,
  options,
  onChange,
  toggleOpen
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleOptionClick = (option) => {
    return () => {
      setSelectedOption(option)
      toggleOpen()
      onChange && onChange(option)
    }
  }

  return (
    <Section bg={backgroundColor}>
      <Container collapseBottomPadding>
        <Grid>
          <Cell gridColumn={{ _: 'span 2' }}>
            {label}
          </Cell>
          <Cell
            gridColumn={{ _: 'span 3' }}
            style={{ columns: label === 'Year' ? '2' : '1' }}
          >
            {options.map((option, i) =>
              <FilterOption key={i}>
                <Link
                  underlined={option.label === selectedOption.label}
                  onClick={handleOptionClick(option)}
                >
                  {option.label}
                </Link>
              </FilterOption>
            )}
          </Cell>
        </Grid>
      </Container>
    </Section>
  )
}

const MobileFilters = ({ filters }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!isOpen)

  return (
    <MobileArchiveHeader
      isOpen={isOpen}
    >
      <MobileArchiveToggle
        isOpen={isOpen}
        onClick={toggleOpen}
      >
        <h2>Filters</h2>
        <ExpandButtonIcon isOpen={isOpen} />
      </MobileArchiveToggle>
      <MobileArchiveContainer display={isOpen ? 'block' : 'none'}>
        {Object.keys(filters).map(key =>
          <Filter
            key={key}
            toggleOpen={toggleOpen}
            {...filters[key]}
          />
        )}
      </MobileArchiveContainer>
    </MobileArchiveHeader>
  )
}

export default MobileFilters
