import styled from 'styled-components'
import { Box } from '~styles/common'

export const NavList = styled(Box)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
`

export const NavItem = styled.li`
  margin-left: 40px;
  margin-bottom: 0;
  padding-left: 0;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    @media (min-width: 1050px) {
      margin-left: auto;
    }
  }

  &:before {
    display: none;
  }
`
