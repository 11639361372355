export const flattenContent = (field, target) => {
  let keys
  if (Array.isArray(target)) {
    keys = [...target]
  } else {
    keys = [target]
  }

  let flattened = { ...field }
  keys.forEach(key => {
    flattened[key] = (field[key] || {}).content
  })
  return flattened
}

export const parseFontSize = (fontSize) => {
  return {
    small: 0,
    default: 1,
    large: 2
  }[fontSize]
}

export const staticSuperTable = (superTable) => {
  return superTable[0] || {}
}

export const mapSection = (section) => {
  switch (section.type) {
    case 'Craft_SectionsTextBlock':
      return {
        ...flattenContent(section, 'body'),
        fontSize: parseFontSize(section.fontSize),
        image: section.image[0]
      }
    case 'Craft_SectionsHtmlblock':
      return {
        ...flattenContent(section, 'body'),
      }
    case 'Craft_SectionsQuoteBlock':
      return {
        ...flattenContent(section, 'quote'),
        image: section.image[0]
      }
    case 'Craft_SectionsImageBlock':
      return {
        ...section,
        image: section.image[0]
      }
    case 'Craft_SectionsDoubleImageBlock':
      return {
        ...section,
        images: section.images
      }
    case 'Craft_SectionsComboBlock':
      return {
        ...flattenContent(section, 'body'),
        image: section.image[0]
      }
    case 'Craft_SectionsInsightBlock':
      return {
        ...flattenContent(section, 'body'),
        image: section.image[0]
      }
    case 'Craft_SectionsProcessBlock':
      return {
        ...section,
        steps: section.steps.map(step => ({
          ...flattenContent(step, 'body'),
          image: step.image[0]
        }))
      }
    case 'Craft_SectionsShowcaseBlock':
      return flattenContent(section, 'body')
    default:
      return section
  }
}
