import React from 'react'
import Layout from '~components/Layout'
import { startScrollSession, saveScrollPosition } from '~utils/scroll-session'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = () => {
  return [0, 0]
}

export const onInitialClientRender = () => {
  startScrollSession()
}

export const onPreRouteUpdate = ({ prevLocation }) => {
  if (prevLocation) {
    const path = prevLocation.pathname
    const scrollPosition = [window.scrollX, window.scrollY]
    saveScrollPosition(path, scrollPosition)
  }
}