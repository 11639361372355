import React from 'react'

import Block from '~components/Block'
import { Input, FormButton, Cell } from '~styles/common'

import useBreakpoint from '~utils/use-breakpoint'

const MailingListBlock = ({ text, ...props }) => {
  const { sm } = useBreakpoint()

  return (
    <Block
      id="subscribe"
      bg="sun"
      heading="Subscribe to Tomorrow"
      mobileOnlyHeading
      {...props}
    >
      <Cell gridColumn={{ _: 'span 5', sm: 'span 5', lg: 'span 5' }}>
        {sm &&
          <span>
            <h2>Subscribe to Tomorrow</h2>
            <br />
            <br />
          </span>
        }
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Cell>
      <Cell gridColumn={{ _: 'span 5', sm: 'span 4', lg: '8 / span 4' }}>
        <form
          action="//studiothick.us5.list-manage.com/subscribe/post?u=ba0e927a4ff367e9bb1a92c77&amp;id=40282ed68c"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
        >
          <label htmlFor="newsletter-email">Email</label>
          <br />
          <Input
            type="email"
            name="EMAIL"
            id="newsletter-email"
            placeholder="you@yours.com"
            required
          />
          <br />
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_ba0e927a4ff367e9bb1a92c77_40282ed68c"
              tabIndex="-1"
            />
          </div>
          <FormButton type="submit">
            Join
          </FormButton>
        </form>
      </Cell>
      <Cell />
    </Block>
  )
}

export default MailingListBlock
