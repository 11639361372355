import styled from 'styled-components'
import {
  system,
  compose,
  color,
  background,
  space,
  layout,
  grid,
  typography,
  border,
  position,
  shadow,
  flexbox
} from 'styled-system'
import theme from '~styles/theme'

const boxSystem = compose(
  color,
  background,
  space,
  layout,
  grid,
  typography,
  border,
  position,
  shadow,
  flexbox,
  system({
    transition: true,
    transform: true,
    pointerEvents: true
  })
)

export const Box = styled.div`
  ${boxSystem}
  p:last-child {
    margin-bottom: 0;
  }
`

export const Section = styled(Box)`
  padding: 20px 20px 0 20px;
  position: relative;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    padding: 20px 90px 0 30px;
  }

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    padding: 20px 120px 0 60px;
  }
`

export const Container = styled(Box)`
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
  padding-bottom: ${props => props.collapseBottomPadding ? '0px' : '90px'};
  position: relative;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding-bottom: ${props => props.collapseBottomPadding ? '0px' : '40px'};
  }
`

export const Grid = styled(Box).attrs(props => ({
  gridTemplateColumns: props.gridTemplateColumns || { _: 'repeat(5, 1fr)', sm: 'repeat(10, 1fr)', lg: 'repeat(12, 1fr)' }
}))`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-bottom: ${props => props.padBottom ? '40px' : '0px'};
`

export const Cell = styled(Box).attrs(props => ({
  gridColumn: props.gridColumn || { _: 'span 5', sm: 'span 10', lg: 'span 12' },
  minHeight: props.minHeight || { _: '40px', sm: '70px', lg: '90px' }
}))`
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    ${props => props.stickyHeading ? 'position: sticky; top: 20px; max-height: calc(100vh - 20px);' : ''}
  }
`

export const Input = styled.input`
  border: 0;
  border-bottom: 3px solid;
  padding: 5px 0;
  background: transparent;
  border-radius: 0;
  margin-bottom: 40px;
  outline: 0;
  &:focus,
  &:active {
    background: rga(255,255,255,0.3);
  }
  &::placeholder {
    color: black;
    opacity: 0.2;
  }
`
export const FormButton = styled.button`
  background: black;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  color: white;
  padding: 16px 40px;
  transition: all 150ms ease-in-out;

  &:hover {
    color: black;
    background: white;
  }
`

