import { staticSuperTable } from '~utils/mapping-filters'

const mapClient = ({ node, relatedEntries }) => {
  return {
    ...node,
    clientType: node.clientType[0],
    caseStudies: relatedEntries.edges.reduce((result, edge) => {
      const caseStudy = edge.node.caseStudy[0]
      if (caseStudy) {
        return result.concat([{
          ...caseStudy,
          featureImage: caseStudy.featureImage[0],
          masthead: staticSuperTable(caseStudy.masthead)
        }])
      }
      return result
    }, [])
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    clients: craft.clients.edges.map(mapClient)
  }
}
