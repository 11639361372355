import React, { useState } from 'react'
import { useCraft } from './query'
import { Cell } from '~styles/common'
import Link from '~components/Link'
import Block from '~components/Block'

const ServicesBlock = ({ ...props }) => {
  const { services, secondaryServices } = useCraft()
  const [selectedService, setSelectedService] = useState(services[0])

  const filteredSecondaryServices = secondaryServices.filter(secondaryService =>
    secondaryService.parent && secondaryService.parent.id === selectedService.id
  )

  return (
    <Block
      bg="white"
      heading="Services"
      stickyHeading
      headingGridColumn={{ _: 'span 5', sm: 'span 9', lg: 'span 3' }}
      {...props}
    >
      <Cell
        stickyHeading
        paddingRight="30px"
        gridColumn={{ _: 'span 2', sm: 'span 5', lg: 'span 4' }}
      >
        {services.map(service =>
          <p key={service.id}>
            <Link
              active={selectedService.id === service.id}
              underlined={selectedService.id !== service.id}
              onClick={() => setSelectedService(service)}
            >
              {service.title}
            </Link>
          </p>
        )}
      </Cell>
      <Cell gridColumn={{ _: 'span 3', lg: 'span 4' }}>
        {filteredSecondaryServices.map(service =>
          <p key={service.id}>
            {service.title}
          </p>
        )}
      </Cell>
      <Cell />
    </Block>
  )
}

export default ServicesBlock
