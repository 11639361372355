import styled from 'styled-components'
import posed from 'react-pose'
import { Box } from '~styles/common'
import theme from '~styles/theme'

const ParallaxFixedPose = posed.div({
  enter: {
    position: 'static',
    applyAtEnd: { position: 'fixed' }
  },
  exit: {
    applyAtStart: { position: 'static' }
  }
})

const ParallaxClonePose = posed.div({
  enter: {
    display: 'none',
    applyAtEnd: { display: 'block' }
  },
  exit: {
    applyAtStart: { display: 'none' }
  }
})

export const ParallaxWrapper = styled(Box)`
  pointer-events: auto;
  padding-bottom: ${props => props.elHeight};
  position: relative;
`

export const ParallaxFixed = styled(ParallaxFixedPose)`
  position: static;
  z-index: 0;
  top: ${props => props.top != null ? props.top + 'px' : 'auto'};
  right: ${props => props.right != null ? props.right + 'px' : 'auto'};
  bottom: ${props => props.bottom != null ? props.bottom + 'px' : 'auto'};
  left: ${props => props.level != null ? props.level * 90 + 'px' : '0'};

  @media screen and (max-width: ${theme.breakpoints.lg}) {
    left: ${props => props.level != null ? props.level * 60 + 'px' : '0'};
  }
`

export const ParallaxClone = styled(ParallaxClonePose)`
  display: none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
`

