import { useStaticQuery, graphql } from 'gatsby'
import { mapCraft } from './mappings'

const query = graphql`
{
  craft {
    entry(section: home) {
      ...on Craft_Home {
        masthead: homeMasthead {
          heading
          subheading {
            content
          }
          images {
            url
          }
        }
        introBlocks: homeIntroBlocks {
          heading
          body {
            content
          }
        }
        impactStatement: homeImpactStatement {
          content
        }
        impactImage: homeImpactImage {
          title
          url
        }
        bcorpStatement: homeBcorpStatement {
          content
        }
        mailingList: homeMailingList {
          content
        }
        metadata {
          ...metadataFields
        }
      }
    }
  }
}
`

export const useCraft = () => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}
