import React from 'react'
import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'
import styled from 'styled-components'

const MastheadImage = styled.img`
  width: 100%;
  display: block;
`

const MastheadContact = ({
  heading,
  backgroundColor,
  image
}) => {
  const color = backgroundColor === 'black' ? 'white' : 'black'

  return (
    <Section
      color={color}
      bg={backgroundColor}
    >
      <Container>
        <Grid>
          <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }}>
            <h1>{heading}</h1>
          </Cell>
          {image &&
            <Cell
              gridColumn={{ _: 'span 5', sm: '6 / span 5', lg: '8 / span 5' }}
              marginTop={{ _: '0', sm: '-20px' }}
              marginRight={{ _: '0', sm: '-30px', lg: '-60px' }}
            >
              <MastheadImage
                src={image.url}
                alt={image.title}
              />
            </Cell>
          }
        </Grid>
      </Container>
    </Section>
  )
}

export default MastheadContact