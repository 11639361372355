import { useStaticQuery, graphql } from 'gatsby'
import { mapCraft } from './mappings'

const query = graphql`
{
  craft {
    entry(section: workItemsArchive) {
      ...on Craft_WorkItemsArchive {
        masthead {
          heading
          subheading {
            content
          }
          image {
            title
            url
          }
          backgroundColor
        }
        metadata {
          ...metadataFields
        }
      }
    }
    workItemYears: entries(section: workItems) {
      ...on Craft_WorkItems {
        year: workItemYear
      }
    }
    services: categories(group: services) {
      id
      title
    }
    impacts: categories(group: impacts) {
      id
      title
    }
  }
}
`

export const useCraft = () => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}