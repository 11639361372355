import React, { useState } from 'react'

import Filter from './Filter'

import { Cell } from '~styles/common'
import { ArchiveHeader } from './style'

const Header = ({ filters, activeFilters }) => {
  const [filterOpen, setFilterOpen] = useState(null)

  return (
    <ArchiveHeader>
      <Cell display={{ _: 'block', sm: 'none', md: 'block' }} style={{paddingTop: '10px', paddingBottom: '10px' }} gridColumn={{ _: 'span 2' }}>
        Year
      </Cell>
      <Cell gridColumn={{ _: 'span 2', sm: 'span 5', md: 'span 4', lg: 'span 5' }}>
        <Filter
          open={filterOpen === filters.projectType.name}
          setOpen={setFilterOpen}
          activeFilters={activeFilters}
          {...filters.projectType}
        />
      </Cell>
      <Cell gridColumn={{ _: 'span 2', lg: '9 / span 2' }}>
        <Filter
          open={filterOpen === filters.impact.name}
          setOpen={setFilterOpen}
          activeFilters={activeFilters}
          {...filters.impact}
        />
      </Cell>
      <Cell position="relative" gridColumn={{ _: 'span 2' }}>
        <Filter
          open={filterOpen === filters.service.name}
          setOpen={setFilterOpen}
          activeFilters={activeFilters}
          {...filters.service}
        />
      </Cell>
    </ArchiveHeader>
  )
}

export default Header
