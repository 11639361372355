import { flattenContent, staticSuperTable } from '~utils/mapping-filters'

const mapEntry = (entry) => {
  const {
    introBlocks,
    impactImage,
    impactStatement,
    bcorpStatement,
    mailingList
  } = entry

  const masthead = staticSuperTable(entry.masthead)
  const metadata = staticSuperTable(entry.metadata)

  return {
    masthead: flattenContent(masthead, 'subheading'),
    introBlocks: introBlocks.map(block =>
      flattenContent(block, 'body')
    ),
    impactImage: impactImage[0],
    impactStatement: impactStatement ? impactStatement.content : null,
    bcorpStatement: bcorpStatement ? bcorpStatement.content : null,
    mailingList: mailingList ? mailingList.content : null,
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    entry: mapEntry(craft.entry)
  }
}
