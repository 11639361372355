import { createGlobalStyle } from 'styled-components'
import theme from './theme'

export default createGlobalStyle`
  html {
    font-family: 'scto', Helvetica, Arial, sans-serif;
    font-size: ${theme.mobileFontSizes.default};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.tabletFontSizes.default};
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSizes.default};
    }
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    letter-spacing: -0.01em;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    transition: .15s all ease-in-out;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  strong {
    font-weight: normal;
    background-color: ${theme.colors.sun};
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: normal;
    line-height: 120%;
    margin: 0;
    letter-spacing: -0.01em;

    > p {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  h1 {
    font-size: ${theme.mobileFontSizes.h1};
    line-height: 1;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.tabletFontSizes.h1};
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSizes.h1};
    }
  }

  input,
  h2 {
    font-size: ${theme.mobileFontSizes.h2};
    margin-bottom: .75rem;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.tabletFontSizes.h2};
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSizes.h2};
    }
  }

  h3,
  h4,
  h5,
  h6,
  p {
    font-size: ${theme.mobileFontSizes.default};
    margin-bottom: 1rem;
    
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.tabletFontSizes.default};
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      font-size: ${theme.fontSizes.default};
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: .75rem 0;

    li {
      position: relative;
      padding-left: 1.2em;
      margin-bottom: .75rem;
      &:before {
        content: '—';
        left: 0;
        margin-right: .5rem;
        position: absolute;
      }
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
    background-color: white;
  }
`
