import { useState, useEffect } from "react"
import algoliasearch from "algoliasearch"

const filterTypes = ["year", "hasCaseStudy", "impacts.id", "services.id"]

const getDefaultParams = () => {
  return {
    query: "",
    page: 0,
    filters: {},
  }
}

const mapParamsToUrl = ({ page, filters }) => {
  if (!window) return

  const url = new URL(window.location.href.replace(window.location.search, ""))

  if (page != null && page > 0) {
    url.searchParams.set("page", page + 1)
  }

  Object.keys(filters).forEach(key => {
    url.searchParams.delete(key)
    filters[key] !== "*" && url.searchParams.append(key, filters[key])
  })

  window.history.replaceState({}, "", url)
}

const getParamsFromUrl = () => {
  if (!window) return

  const url = new URL(window.location.href)
  let params = getDefaultParams()

  const page = url.searchParams.get("page")
  if (page) {
    params.page = page - 1
  }

  filterTypes.forEach(key => {
    const filter = url.searchParams.get(key)
    if (filter) {
      params.filters[key] = filter
    }
  })

  return params
}

export const useSearch = (indexName, { mapToUrl }, searchConfig) => {
  const [params, setParams] = useState(getDefaultParams())
  const [results, setResults] = useState({})
  const [loading, setLoading] = useState(true)

  const client = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    process.env.ALGOLIA_SEARCH_API_KEY
  )
  const index = client.initIndex(indexName)

  const facetFilters = filters => {
    const filterKeys = Object.keys(filters).filter(
      key => filters[key] && filters[key] !== "*"
    )
    return filterKeys.map(key => `${key}:${filters[key]}`)
  }

  const refine = ({
    query = params.query,
    page = params.page,
    filters = {},
    clear = false,
    initial = false,
  } = {}) => {
    let nextParams = {
      query,
      page: clear ? 0 : page,
      filters: clear ? filters : { ...params.filters, ...filters },
    }

    if (mapToUrl && initial) {
      nextParams = getParamsFromUrl()
    }
    if (mapToUrl && !initial) {
      mapParamsToUrl(nextParams)
    }

    setParams(nextParams)
    setLoading(true)

    index
      .search({
        query: nextParams.query,
        page: nextParams.page,
        facetFilters: facetFilters(nextParams.filters),
        ...searchConfig,
      })
      .then(results => {
        setResults(results)
        setLoading(false)
      })
  }

  useEffect(() => {
    refine({ initial: true })
  }, [])

  return {
    results,
    loading,
    params,
    refine,
  }
}
