import React, { useState } from 'react'

import Link from '~components/Link'
import { ArchiveFilter, FilterDropdown, FilterOption } from './style'

const Filter = ({
  name,
  options,
  backgroundColor,
  onChange,
  open,
  setOpen,
  activeFilters
}) => {
  let selectedOption = options[0]
  if (activeFilters[name] != null) {
    selectedOption = options.filter(option =>
      option.value == activeFilters[name]
    )[0]
  }

  const handleOptionClick = (option) => {
    return () => {
      setOpen(null)
      onChange && onChange(option)
    }
  }

  return (
    <ArchiveFilter>
      <Link style={{ textTransform: 'capitalize' }} activeColor={backgroundColor} active={selectedOption.label.indexOf('All') < 0} underlined onClick={() => setOpen(name)}>
        {options[0].label.replace('All ', '')}
      </Link>
      {open &&
        <FilterDropdown bg={backgroundColor}>
          {options.map((option, i) =>
            <FilterOption key={i}>
              <Link
                active={option == selectedOption}
                onClick={handleOptionClick(option)}
              >
                {option.label}
              </Link>
            </FilterOption>
          )}
        </FilterDropdown>
      }
    </ArchiveFilter>
  )
}

export default Filter
