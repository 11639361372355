import React from 'react'

import { Box, Cell } from '~styles/common'
import { ArchiveRow, CaseStudyLink, CaseStudyImage, FullSpanLink } from './style'
import Link from '~components/Link'
import useBreakpoint from '~utils/use-breakpoint'

const WorkItem = ({ item, handleFilterChange }) => {
  const { sm } = useBreakpoint()

  return (
    <ArchiveRow>
      <Cell display={{ _: 'block', sm: 'none', md: 'block' }} gridColumn={{ _: 'span 1', sm: 'span 2' }}>
        {item.year}
      </Cell>
      <Cell gridColumn={{ _: '2 / span 5', sm: 'span 5', md: 'span 4', lg: 'span 5' }}>
        {item.title}
      </Cell>
      {sm &&
        <>
          <Cell paddingBottom={0} gridColumn={{ _: '2 / span 4', sm: 'span 2', lg: '9 / span 2' }}>
            <Link
              underlined
              replace
              onClick={() => handleFilterChange("impacts.id")({label: item.impact.title, value: item.impact.id})}
            >
              {item.impact && item.impact.title}
            </Link>
          </Cell>
          <Cell gridColumn={{ _: '2 / span 4', sm: 'span 2'  }}>
            <Link
              underlined
              replace
              onClick={() => handleFilterChange("services.id")({label: item.service.title, value: item.service.id})}
            >
              {item.service && item.service.title}
            </Link>
          </Cell>
        </>
      }
      {!sm &&
        <Cell minHeight='auto' paddingBottom={0} gridColumn={{ _: '2 / span 4' }}>
          <Link
            underlined
            replace
            onClick={() => handleFilterChange("impacts.id")({label: item.impact.title, value: item.impact.id})}
          >
            {item.impact && item.impact.title}<br />
          </Link>
          <Link
            underlined
            replace
            onClick={() => handleFilterChange("services.id")({label: item.service.title, value: item.service.id})}
          >
            {item.service && item.service.title}
          </Link>
        </Cell>
      }
    </ArchiveRow>
  )
}

const CaseStudy = ({ item, handleFilterChange }) => {
  const { sm } = useBreakpoint()

  return (
    <ArchiveRow caseStudy>
      <FullSpanLink bg="#fff" to={`/${item.caseStudy.uri}`} />

      <Cell gridColumn={{ _: 'span 1', sm: 'span 2' }}>
        {item.year}
      </Cell>
      <Cell
        position="relative"
        minHeight={item.caseStudy.image && '240px'}
        gridColumn={{ _: 'span 4', sm: 'span 4', lg: 'span 5' }}
      >
        <Box position="relative" zIndex="10">
          <h2>{item.title}</h2>
          {item.caseStudy.image &&
            <CaseStudyImage
              backgroundImage={`url(${item.caseStudy.image.url})`}
            />
          }
          <CaseStudyLink>
            See case study
          </CaseStudyLink>
        </Box>
      </Cell>
      {sm &&
        <>
          <Cell zIndex="100" paddingBottom={0} gridColumn={{ _: '2 / span 4', sm: 'span 2', lg: '9 / span 2' }}>
            <Link
              underlined
              replace
              onClick={() => handleFilterChange("impacts.id")({label: item.impact.title, value: item.impact.id})}
            >
              {item.impact && item.impact.title}<br />
            </Link>
          </Cell>
          <Cell zIndex="100" gridColumn={{ _: '2 / span 4', sm: 'span 2'  }}>
            <Link
              underlined
              replace
              onClick={() => handleFilterChange("services.id")({label: item.service.title, value: item.service.id})}
            >
              {item.service && item.service.title}
            </Link>
          </Cell>
        </>
      }
      {!sm &&
        <Cell minHeight='auto' paddingBottom={0} gridColumn={{ _: '2 / span 4', sm: 'span 2' }}>
          <Link
            underlined
            replace
            onClick={() => handleFilterChange("impacts.id")({label: item.impact.title, value: item.impact.id})}
          >
            {item.impact && item.impact.title}<br />
          </Link>
          <Link
            underlined
            replace
            onClick={() => handleFilterChange("services.id")({label: item.service.title, value: item.service.id})}
          >
            {item.service && item.service.title}
          </Link>
        </Cell>
      }
    </ArchiveRow>
  )
}

const Item = ({ item, ...props }) => {
  if (item.caseStudy) {
    return <CaseStudy item={item} {...props} />
  }
  return <WorkItem item={item} {...props}/>
}

export default Item
