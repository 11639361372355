import styled from 'styled-components'
import { Box } from '~styles/common'
import theme from '~styles/theme'

export const MenuIcon = styled.i`
  width: 20px;
  height: 8px;
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: currentColor;
    top: 0;
    left: 0;
    transform: none;
    transition: all .15s ease-in-out;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: currentColor;
    bottom: 0;
    left: 0;
    transform: none;
    transition: all .15s ease-in-out;
  }
`

export const FixedMenuWrap = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  padding-left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.97);
  z-index: 998;
  opacity: ${props => !props.isOpen ? '0' : '1'};
  pointer-events: ${props => !props.isOpen ? 'none' : 'all'};
  transition: opacity .15s ease-in-out;
`

export const NavWrapper = styled(Box)`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 140px 120px;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    align-items: flex-start;
    padding: 10px 0px;
  }
`
export const NavList = styled(Box)`
  margin: 0;
`

export const NavItem = styled.li`
  color: #fff;
  display: block;
  &:before {
    display: none;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding-left: 20px;
  }
`

export const FixedMenuButtonText = styled(Box)`
  padding-right: 15px;
  transition: opacity .15s ease-in-out;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    position: absolute;
    top: 70px;
    left: 23px;
    transform: translateX(-40%) rotate(90deg);
    transform-origin: 50% 50%;
    padding-right: 0;
    opacity: 1;
  }
`

export const FixedMenuButton = styled.button`
  /* Button reset (start) */
  width: auto;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;
  /* Button reset (end) */
  
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 15px 20px;
  background: ${props => (!props.scrollHidden || props.isOpen) ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)'};
  color: ${props => (!props.scrollHidden || props.isOpen) ? 'white' : 'black'};
  position: fixed;
  top: 5px;
  right: 0px;
  z-index: 999;

  transition: all .15s ease-in-out;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    top: 0;
    right: 0;
    padding: 30px 20px;
    background: black;
    color: white;
    height: 100vh;
  }

  ${MenuIcon} {
    &:before {
      color: ${props => props.isOpen || !props.scrollHidden ? 'white' : 'black'};
      top: ${props => props.isOpen ? 'calc(50% - 1px)' : '0'};
      transform: ${props => props.isOpen ? 'rotate(-135deg)' : 'none'};
      @media screen and (min-width: ${theme.breakpoints.sm}) {
        color: white;
      }
    }
    &:after {
      bottom: ${props => props.isOpen ? 'calc(50% - 1px)' : '0'};
      color: ${props => props.isOpen || !props.scrollHidden ? 'white' : 'black'};
      transform: ${props => props.isOpen ? 'rotate(135deg)' : 'none'};
      @media screen and (min-width: ${theme.breakpoints.sm}) {
        color: white;
      }
    }
  }
`
