import styled from 'styled-components'
import { Grid } from '~styles/common'
import theme from '~styles/theme'

export const StaffMemberGrid = styled(Grid)`
  grid-template-columns: repeat(6, 1fr) !important;

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    grid-template-columns: repeat(8, 1fr) !important;
  }
`

export const StaffMemberOverlay = styled.div`
  position: absolute;
  top: 75%;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  transition: .1s all;
  border: 0;
  height: 25%;
  overflow: hidden;

  p {
    margin-bottom: .5rem;
  }
`

export const StaffMember = styled.div`
  position: relative;

  &:after {
    display: block;
    content: '';
    background-color: #E6E5EA;
    right: -20px;
    height: 100%;
    width: 20px;
    top: 0;
    position: absolute;
    display: ${({ third }) => `${third ? 'none' : 'block' }`};
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  }

  img {
    width: 100%;
    display: block;
  }

  &:hover {
    ${StaffMemberOverlay} {
      top: 0;
      opacity: 1;
      height: 100%;
      pointer-events: all;
    }
  }
`

