const mapService = (service) => {
  return {
    ...service,
    parent: service.parent ? service.parent[0] : null
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    services: craft.services,
    secondaryServices: craft.secondaryServices.map(mapService)
  }
}
