import React, { useState } from "react"

import { Section, Container, Grid, Cell, Box } from "~styles/common"

import Header from "./Header"
import Item from "./Item"
import Pagination from "./Pagination"
import MobileFilters from "./MobileFilters"

import useBreakpoint from "~utils/use-breakpoint"

const getFilters = ({ impacts, services, years, refine }) => {
  const handleFilterChange = name => {
    return option => {
      refine({ filters: { [name]: option.value }, clear: true })
    }
  }

  return {
    handleFilterChange,
    projectType: {
      name: "hasCaseStudy",
      label: "Project type",
      options: [
        { label: "All projects", value: "*" },
        { label: "Just case studies", value: "true" },
      ],
      mobileTitle: "",
      backgroundColor: "seafoam",
      onChange: handleFilterChange("hasCaseStudy"),
    },
    impact: {
      name: "impacts.id",
      label: "Category",
      options: [
        { label: "All categories", value: "*" },
        ...impacts.map(({ id, title }) => ({
          label: title,
          value: id.toString(),
        })),
      ],
      backgroundColor: "sun",
      onChange: handleFilterChange("impacts.id"),
    },
    service: {
      name: "services.id",
      label: "Service",
      options: [
        { label: "All services", value: "*" },
        ...services.map(({ id, title }) => ({
          label: title,
          value: id.toString(),
        })),
      ],
      backgroundColor: "sky",
      onChange: handleFilterChange("services.id"),
    },
  }
}

const WorkArchive = ({
  workItems,
  services,
  impacts,
  years,
  loading,
  refine,
  params,
  numberOfPages,
  page,
}) => {
  const { sm } = useBreakpoint()
  const filters = getFilters({ impacts, services, years, refine })
  const activeFilters = params.filters

  return (
    <Section bg="white" minHeight="100vh">
      <Container>
        {!sm && (
          <MobileFilters filters={filters} activeFilters={activeFilters} />
        )}
        {sm && <Header filters={filters} activeFilters={activeFilters} />}
        <Box style={{marginTop: "-10px"}}>
          {workItems &&
            workItems.map(item => <Item key={item.id} item={item} handleFilterChange={filters.handleFilterChange} />)}
        </Box>
        <Grid>
          {!loading && !workItems && (
            <Cell
              gridColumn={{ _: "span 5", sm: "2 / span 5", lg: "3 / span 6" }}
            >
              An error occurred :(
            </Cell>
          )}
          {!loading && workItems.length < 1 && (
            <Cell
              gridColumn={{ _: "span 5", sm: "2 / span 5", lg: "3 / span 6" }}
            >
              No work items
            </Cell>
          )}
          {loading && !workItems && (
            <Cell
              gridColumn={{ _: "span 5", sm: "2 / span 5", lg: "3 / span 6" }}
            >
              Loading...
            </Cell>
          )}
          <Cell />
          <Cell
            gridColumn={{ _: "span 5", sm: "2 / span 5", lg: "3 / span 6" }}
          >
            <Pagination
              numberOfPages={numberOfPages}
              page={page}
              onChange={value => {
                window.scroll(0, 0)
                refine({ page: value })
              }}
            />
          </Cell>
          <Cell />
        </Grid>
      </Container>
    </Section>
  )
}

export default WorkArchive
