import React from 'react'

import { Box } from '~styles/common'
import { PaginationPage } from './style'

const Pagination = ({ page, numberOfPages, onChange }) => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      {[...Array(numberOfPages)].map((_, i) =>
        <PaginationPage
          key={i}
          active={i === page}
          onClick={() => onChange(i)}
        >
          {i + 1}
        </PaginationPage>
      )}
    </Box>
  )
}

export default Pagination
