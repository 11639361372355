import styled from 'styled-components'

export const FlagHolder = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  img {
    height: 45px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`
