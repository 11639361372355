import React from 'react'
import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'
import Link from '~components/Link'
import useBreakpoint from '~utils/use-breakpoint'

const workArchiveUrl = ({ year, impact, service }) => {
  if (year) {
    return `/work?year=${year}`
  }
  if (impact) {
    return `/work?impacts.id=${impact.id}`
  }
  if (service) {
    return `/work?services.id=${service.id}`
  }
  return '/work'
}

const MastheadCaseStudy = ({
  heading,
  backgroundColor,
  year,
  website,
  externallink,
  client,
  impact,
  service
}) => {
  const { sm } = useBreakpoint()
    
  return (
    <>
      <Section bg={backgroundColor}>
        <Container collapseBottomPadding={true}>
          <Grid padBottom>
            <Cell gridColumn={{ _: 'span 5', lg: 'span 6' }}>
              <h1>{heading}</h1>
            </Cell>
            <Cell gridColumn={{ _: 'span 5', lg: '8 / span 5' }}>
              {year}
            </Cell>
            <Cell />
            <Cell gridColumn={{ _: 'span 5', sm: 'span 4', lg: 'span 6' }}>
              <div
                dangerouslySetInnerHTML={{ __html: externallink }}
              />
            </Cell>
            {sm &&
              <>
                <Cell gridColumn={{ _: 'span 5', sm: '6 / span 2', lg: '8 / span 2' }}>
                  <Link
                    underlined
                    to={workArchiveUrl({ impact })}
                  >
                    {impact.title}
                  </Link>
                </Cell>
                <Cell gridColumn={{ _: 'span 5', sm: 'span 2' }}>
                  <Link
                    underlined
                    to={workArchiveUrl({ service })}
                  >
                    {service.title}
                  </Link>
                </Cell>
              </>
            }
            {!sm &&
              <Cell gridColumn={{ _: 'span 5', sm: '6 / span 2', lg: '8 / span 2' }}>
                <Link
                  underlined
                  to={workArchiveUrl({ impact })}
                >
                  {impact.title}
                </Link>
                <br />
                <Link
                  underlined
                  to={workArchiveUrl({ service })}
                >
                  {service.title}
                </Link>
              </Cell>
            }
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default MastheadCaseStudy
