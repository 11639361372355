import React from 'react'
import PropTypes from 'prop-types'

import {
  Section,
  Container,
  Grid,
  Cell,
  Box
} from '~styles/common'
import Link from '~components/Link'
import TodayLogo from '~components/TodayLogo'
import Parallax from '~components/Parallax'
import { NavList, NavItem } from './style'

export const navigation = [
  {
    title: 'Home',
    href: '/'
  }, {
    title: 'Work',
    href: '/work'
  }, {
    title: 'Careers',
    href: '/careers'
  }, {
    title: 'Contact',
    href: '/contact'
  }, {
    title: 'Business enquiries',
    href: '/business'
  }
]

const MainHeader = () => {
  return (
    <>
      <Parallax>
        <Box
          as="header"
          bg="black"
          color="white"
        >
          <Section>
            <Container collapseBottomPadding={true}>
              <Grid>
                <Cell
                  gridColumn={{ _: 'span 3', sm: 'span 3', lg: 'span 4' }}
                >
                  <Link hoverless to="/">
                    <TodayLogo height={{ _: '22px', sm: '30px', lg: '36px' }} />
                  </Link>
                </Cell>
                <Cell
                  gridColumn={{ _: 'span 3', sm: '4 / span 7', lg: '5 / span 9' }}
                  display={{ _: 'none', sm: 'block' }}
                >
                  <NavList as="ul">
                    {navigation.map(item =>
                      <NavItem as="li" key={item.href}>
                        <Link
                          active={item.href === '/'}
                          to={item.href}
                        >
                          {item.title}
                        </Link>
                      </NavItem>
                    )}
                  </NavList>
                </Cell>
              </Grid>
            </Container>
          </Section>
        </Box>
      </Parallax>
    </>
  )
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
}

MainHeader.defaultProps = {
  siteTitle: ``,
}

export default MainHeader
