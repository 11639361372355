import { useStaticQuery, graphql } from 'gatsby'
import { mapCraft } from './mappings'

const query = graphql`
{
  craft {
    clientTypes: categories(group: clientTypes) {
      ...on Craft_ClientTypesCategory {
        id
        title
      }
    }
    clients: categoriesConnection(group: clients, orderBy: "title") {
      edges {
        node {
          ...on Craft_ClientsCategory {
            id
            title
            clientType {
              id
            }
          }
        }
        relatedEntries(section: workItems) {
          edges {
            node {
              ...on Craft_WorkItems {
                caseStudy {
                  ...on Craft_CaseStudies {
                    uri
                    masthead: caseStudyMasthead {
                      image {
                        title
                        url
                      }
                    }
                    featureImage: caseStudyFeatureImage {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const useCraft = () => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}
