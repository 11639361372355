import React from "react"

import PageLayer from "~components/PageLayer"
import SEO from "~components/SEO"
import Parallax from "~components/Parallax"
import Masthead from "~components/Masthead"
import WorkArchive from "./components/WorkArchive"

import { useSearch } from "~utils/search"

import { useCraft } from "./query"
import { mapWorkItem } from "./mappings"

const WorkTemplate = ({ location, isBackground }) => {
  /*
   * The work page must use a static query
   * as it will be rendered as a background
   * page in the layout component.
   */
  const { entry, workItemYears, services, impacts } = useCraft()

  const {
    results: { hits, page, nbPages },
    refine,
    params,
    loading,
  } = useSearch(
    "work_items",
    { mapToUrl: !isBackground },
    {
      hitsPerPage: 100,
    }
  )

  let workItems
  if (hits) {
    workItems = hits.map(mapWorkItem)
  }

  const years = workItemYears.reduce((result, { year }) => {
    return result.includes(year) ? result : [...result, year].sort().reverse()
  }, [])

  return (
    <PageLayer isBackground={isBackground} location={location}>
      {!isBackground && (
        <SEO title={entry.masthead.heading} metadata={entry.metadata} />
      )}
      <Parallax level={1}>
        <Masthead {...entry.masthead} />
      </Parallax>
      <WorkArchive
        workItems={workItems}
        services={services}
        impacts={impacts}
        years={years}
        loading={loading}
        refine={refine}
        params={params}
        page={page}
        numberOfPages={nbPages}
      />
    </PageLayer>
  )
}

export default WorkTemplate
