const colors = {
  black: '#000',
  white: '#fff',
  dusk: '#F9DDF9',
  seafoam: '#C0DEA7',
  sun: '#FFDB58',
  sky: '#B8DBE8',
  clay: '#F58153',
  neutral: '#ECE7E3',
  grey: '#E6E6E8'
}

const space = [0, 5, 10, 20, 50, 60, 90]

const fontSizes = ['22px', '32px', '50px']

fontSizes.default = fontSizes[0]
fontSizes.h2 = fontSizes[1]
fontSizes.h1 = fontSizes[2]

const tabletFontSizes = ['19px', '26px', '42px']

tabletFontSizes.default = tabletFontSizes[0]
tabletFontSizes.h2 = tabletFontSizes[1]
tabletFontSizes.h1 = tabletFontSizes[2]

const mobileFontSizes = ['16px', '24px', '36px']

mobileFontSizes.default = mobileFontSizes[0]
mobileFontSizes.h2 = mobileFontSizes[1]
mobileFontSizes.h1 = mobileFontSizes[2]

const breakpoints = ['720px', '900px', '1200px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]

export default {
  colors,
  space,
  fontSizes,
  mobileFontSizes,
  tabletFontSizes,
  breakpoints
}
