import styled from 'styled-components'

export const ClientHoverImages = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 45vw;
  max-height: 420px;
  height: calc(100vh - 440px);
`

export const ClientHoverImagesInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;

  img {
    position: absolute;
    max-height: 100%;
    max-width: 90%;
    top: 0;
  }
`
