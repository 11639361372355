import React, { useState } from 'react'
import { useCraft } from './query'
import { Cell } from '~styles/common'
import { ClientHoverImages, ClientHoverImagesInner } from './style'
import Link from '~components/Link'
import Block from '~components/Block'
import useBreakpoint from '~utils/use-breakpoint'

const ClientImage = ({ showClientImage, index, caseStudies }) => {
  if (caseStudies.length > 0) {
    const caseStudy = caseStudies[0]

    if (caseStudy.featureImage) {
      return (
        <img
          data-index={index}
          alt={caseStudy.featureImage.title}
          style={{ display: index === showClientImage ? 'block' : 'none' }}
          src={caseStudy.featureImage.url}
        />
      )
    }
  }

  return ''
}

const Client = ({ title, caseStudies, index, setShowClientImage }) => {
  if (caseStudies.length > 0) {
    const caseStudy = caseStudies[0]
    return (
      <p>
        <Link
          onMouseOver={() => setShowClientImage(index)}
          underlined
          to={`/${caseStudy.uri}`}
        >
          {title}
        </Link>
      </p>
    )
  }

  return (
    <p>{title}</p>
  )
}

const ClientsBlock = ({ ...props }) => {
  const { clientTypes, clients } = useCraft()
  const [selectedClientType, setSelectedClientType] = useState(clientTypes[0])

  const filteredClients = clients.filter(client =>
    client.clientType && client.clientType.id === selectedClientType.id
  )
  const caseStudiesWithImages = filteredClients.filter(client =>
    client.caseStudies.length && client.caseStudies[0].featureImage
  )

  const [showClientImage, setShowClientImage] = useState(caseStudiesWithImages.length && caseStudiesWithImages[0].id)

  const { sm } = useBreakpoint()

  return (
    <Block
      heading="Client partners"
      mobileOnlyHeading
      bg="white"
      {...props}
    >
      {sm &&
        <Cell
          stickyHeading
          gridColumn={{ _: 'span 4', sm: 'span 9', lg: 'span 3' }}
        >
          <h2>Client partners</h2>
          <ClientHoverImages>
            <ClientHoverImagesInner>
              {clients.map((client, index) =>
                <ClientImage
                  key={client.id}
                  showClientImage={showClientImage}
                  index={client.id}
                  {...client}
                />
              )}
            </ClientHoverImagesInner>
          </ClientHoverImages>
        </Cell>
      }
      <Cell
        stickyHeading
        maxHeight="calc(100vh - 20px)"
        gridColumn={{ _: 'span 2', sm: 'span 5', lg: 'span 3' }}
      >
        {clientTypes.map(clientType =>
          <p key={clientType.id}>
            <Link
              active={selectedClientType.id === clientType.id}
              underlined={selectedClientType.id !== clientType.id}
              onClick={() => setSelectedClientType(clientType)}
            >
              {clientType.title}
            </Link>
          </p>
        )}
      </Cell>
      <Cell gridColumn={{ _: 'span 3', sm: 'span 5' }}>
        {filteredClients.map((client, index) =>
          <Client
            key={client.id}
            setShowClientImage={setShowClientImage}
            index={client.id}
            {...client}
          />
        )}
      </Cell>
      <Cell />
    </Block>
  )
}

export default ClientsBlock
