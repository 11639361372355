import React from 'react'
import { Cell } from '~styles/common'
import Block from '~components/Block'
import bcorpSvg from '~assets/images/bcorp.svg'

const ImpactBlock = ({
  impactStatement,
  impactImage,
  bcorpStatement,
  ...props
}) => {
  return (
    <Block
      bg="white"
      heading="Purpose"
      {...props}
    >
      <Cell gridColumn={{ _: 'span 5', sm: '6 / span 5', lg: '8 / span 5' }}>
        <h2
          dangerouslySetInnerHTML={{ __html: impactStatement }}
        />
      </Cell>
      <Cell />
      <Cell gridColumn={{ _: 'span 5', sm: 'span 4', lg: 'span 6' }}>
        {impactImage &&
          <img
            src={impactImage.url}
            alt={impactImage.title}
            width="100%"
          />
        }
      </Cell>
      <Cell gridColumn={{ _: 'span 1', sm: '6 / span 1', lg: '8 / span 1' }}>
        <img src={bcorpSvg} alt="B corp logo" width="100%" />
      </Cell>
      <Cell gridColumn={{ _: 'span 4', sm: '8 / span 3', lg: '10 / span 3' }}>
        <div
          dangerouslySetInnerHTML={{ __html: bcorpStatement }}
        />
      </Cell>
      <Cell />
    </Block>
  )
}

export default ImpactBlock
