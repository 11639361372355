import React from 'react'
import { PoseGroup } from 'react-pose'
import { PoseInner } from './style'

import HomePage from '~templates/home'
import WorkPage from '~templates/work'

const pages = [
  {
    path: '/',
    component: HomePage
  }, {
    path: '/work',
    component: WorkPage
  }
]

const BackgroundPages = ({ location }) => {
  return pages.reduce((result, { path, component }) => {
    if (
      location.pathname.includes(path) &&
      location.pathname.replace(/\/$/, '') !== path.replace(/\/$/, '')
    ) { 
      const element = React.createElement(component, {
        key: path,
        location: { pathname: path },
        isBackground: true
      })
      return result.concat([element])
    }
    return result
  }, [])
}

const PageContext = ({ children, location }) => {
  return (
    <PoseGroup>
      <PoseInner key={location.pathname}>
        <BackgroundPages location={location} />
        {children}
      </PoseInner>
    </PoseGroup>
  )
}

export default PageContext
