import React from 'react'
import {
  Section,
  Container,
  Grid,
  Cell
} from '~styles/common'

const MastheadBusinessEnquiries = ({
  heading,
  backgroundColor,
  image,
}) => {
  return (
    <>
      <Section bg={backgroundColor}>
        <Container collapseBottomPadding={true}>
          <Grid>
            <Cell>
              <h1>{heading}</h1>
            </Cell>
            {image &&
              <Cell gridColumn={{ _: 'span 5', lg: '6 / span 7' }}>
                <img
                  alt={image.title}
                  src={image.url}
                  width="100%"
                />
              </Cell>
            }
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default MastheadBusinessEnquiries
