import styled from 'styled-components'

export const Home = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &[aria-hidden=true] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transform: none;
    cursor: pointer;
  }
`
