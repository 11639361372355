import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  lang,
  title,
  type,
  metadata
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const description = metadata.metaDescription || site.siteMetadata.description

  const socialImageUrl = (metadata.socialImage && metadata.socialImage[0]) ? metadata.socialImage[0].url : ''

  const meta = [
    {
      name: 'description',
      content: description
    },
    {
      property: 'og:title',
      content: metadata.metaTitle || title
    },
    {
      property: 'og:description',
      content: description
    },
    {
      property: 'og:type',
      content: type || 'website'
    },
    {
      property: 'og:image',
      content: socialImageUrl
    },
    {
      name: 'twitter:card',
      content: 'summary'
    },
    {
      name: 'twitter:title',
      content: metadata.metaTitle || title
    },
    {
      name: 'twitter:description',
      content: description
    },
    {
      property: 'twitter:image',
      content: socialImageUrl
    }
  ].filter(({ content }) => !!content)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={meta}
    />
  )
}

SEO.defaultProps = {
  lang: 'en'
}

export default SEO
