import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import theme from '~styles/theme'

const borderBottomColor = ({ underlined, hoverless }, hover = false) => {
  if (hover && !hoverless) {
    return underlined ? 'transparent' : 'currentColor'
  }
  return underlined ? 'currentColor' : 'transparent'
}

const linkStyle = css`
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => borderBottomColor(props)};
  transition: .15s all ease-in-out;

  &:hover {
    border-bottom-color: ${props => borderBottomColor(props, true)}
  }
`

export const AnchorLink = styled.a`
  ${linkStyle}
`

export const GatsbyLink = styled(
  ({ underlined, hoverless, ...other }) => <Link {...other} />
)`
  ${linkStyle}
`

export const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;
  ${props => props.active && `
    position: relative;
    padding-left: 1em;
  `}

  ${props => props.active && `
    &:before {
      content: '●';
      font-size: 0.9em;
      color: ${theme.colors[props.activeColor] || 'inherit'};
      margin-left: -1.2em;
      top: 0;
      position: absolute;
      line-height: 1.5;
    }`}

  &:hover {
    span {
      border-bottom-color: ${props => borderBottomColor(props, true)};
    }
  }

  span {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${props => borderBottomColor(props)};
    transition: .15s all ease-in-out;
  }
`
