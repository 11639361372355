import React, { useState } from 'react'
import { Location } from '@reach/router'
import PropTypes from 'prop-types'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import {
  MenuIcon,
  FixedMenuButton,
  FixedMenuButtonText,
  FixedMenuWrap,
  NavWrapper,
  NavList,
  NavItem
} from './style'
import Link from '~components/Link'

const FixedMenu = ({
  navigation,
}) => {
  const [scrollHidden, setScrollHidden] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => setMenuOpen(true)
  const closeMenu = () => setMenuOpen(false)

  useScrollPosition(
    ({ currPos }) => { setScrollHidden(currPos.y > 90) },
    [scrollHidden],
    false,
    true,
    80
  )

  return (
    <>
      <FixedMenuButton
        className="MenuButton"
        isOpen={menuOpen}
        scrollHidden={scrollHidden}
        onClick={menuOpen ? closeMenu : openMenu}
      >
        <FixedMenuButtonText
          scrollHidden={scrollHidden}
        >Menu</FixedMenuButtonText>
      <MenuIcon scrollHidden={scrollHidden} />
      </FixedMenuButton>
      <FixedMenuWrap
        isOpen={menuOpen}
        as="menu"
      >
        <NavWrapper>
          <Location>
            {({location}) =>

              <NavList as="ul">
                {navigation.map(item =>
                  <NavItem key={item.href}>
                    <h1>
                      <Link
                        active={
                          (item.href === '/' && location.pathname === '/') ||
                           item.href === location.pathname.replace(/\/$/, '')
                        }
                        to={item.href}
                        onClick={closeMenu}
                      >
                        {item.title}
                      </Link>
                    </h1>
                  </NavItem>
                )}
              </NavList>
             }
          </Location>
        </NavWrapper>
      </FixedMenuWrap>
    </>
  )
}

FixedMenu.propTypes = {
  isOpen: PropTypes.bool,
  scrollHidden: PropTypes.bool,
}

FixedMenu.defaultProps = {
  isOpen: false,
  scrollHidden: false
}

export default FixedMenu
