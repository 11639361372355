import { useStaticQuery, graphql } from 'gatsby'
import { mapCraft } from './mappings'

const query = graphql`
{
  craft {
    services: categories(group: services) {
      ...on Craft_ServicesCategory {
        id
        title
      }
    }
    secondaryServices: categories(group: secondaryServices) {
      ...on Craft_SecondaryServicesCategory {
        id
        title
        parent: services {
          id
        }
      }
    }
  }
}
`

export const useCraft = () => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}