import styled from 'styled-components'

import theme from '~styles/theme'
import Link from '~components/Link'
import { Grid, Cell, Box } from '~styles/common'
import { ExpandButton } from '~components/Block/style'

export const ArchiveFilter = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const FilterDropdown = styled(Box)`
 padding: 10px 20px;
 padding-right: 40px;
 margin-left: -20px;
 position: absolute;
 top: 0;
 left: 0;
 width: 335px;
 z-index: 10;
`

export const FilterOption = styled(Box)`
  margin-bottom: 20px;
`

export const ArchiveRow = styled(Grid)`
  border-bottom: 1px solid black;
  border-bottom-color: ${theme.colors.neutral};
  padding-bottom: 40px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;

  &:hover {
    ${props => props.caseStudy ? 'border-color: transparent;': ''}
  }

  &:last-child {
    border-bottom: 0;
  }
`

export const MobileArchiveHeader = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 997;
  height: ${({ isOpen }) => isOpen ? '100vh' : 'auto'};
  overflow: auto;
`

export const MobileArchiveToggle = styled(ExpandButton)`
  ${({ isOpen }) => isOpen ? 'position: fixed; top: 0;': ''}
`

export const MobileArchiveContainer = styled(Box)`
  top: 68px;
  height: calc(100vh - 68px);
  overflow: auto;
  position: fixed;
  width: 100%;
`

export const ArchiveHeader = styled(Grid)`
  z-index: 20;
  background: white;
  border-bottom: 1px solid black;
  border-bottom-color: ${theme.colors.neutral};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;

  ${Cell} {
    min-height: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    position: sticky;
    top: 0;
    padding: 0;
  }
`

export const PaginationPage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.active ? 'currentColor' : 'transparent'};
  margin-right: 20px;
  cursor: pointer;
`

export const CaseStudyImage = styled(Box)`
  position: absolute;
  left: 0;
  right: -7vw;
  bottom: -20px;
  height: 200px;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  z-index: -1;
  @media (max-width: 1100px) {
    background-position: left top;
    margin-top: 40px;
    position: static;
  }
`

export const CaseStudyLink = styled.span`
  border-bottom: 1px solid;
  display: inline-block;
  margin-top: 20px;
  @media (min-width: 1101px) {
    margin-bottom: 130px;
    margin-top: 0px;
  }
`

export const FullSpanLink = styled(Link)`
  background-color: transparent;
  position: absolute;
  top: -21px;
  left: -20px;
  right: -20px;
  bottom: -2px;
  z-index: 99;
  mix-blend-mode: multiply;
  transition: all .15s ease-in-out;
  &:hover {
    border: 0;
    background-color: ${theme.colors.neutral};
  }
`
