import React, { useState } from "react"
import { useCraft } from "./query"

import { Cell } from "~styles/common"
import Link from "~components/Link"
import Block from "~components/Block"
import { StaffMemberGrid, StaffMember, StaffMemberOverlay } from "./style"

const TeamBlock = ({ ...props }) => {
  const { crafts, staffMembers } = useCraft()
  const [selectedCraft, setSelectedCraft] = useState(crafts[0])
  const filteredStaffMembers = staffMembers.filter(staffMember =>
    staffMember.craft.some(
      assignedCraft => assignedCraft.id === selectedCraft.id
    )
  )

  return (
    <Block
      bg="white"
      heading="Team"
      stickyHeading
      headingGridColumn={{ _: "span 4", sm: "span 9", lg: "span 3" }}
      {...props}
    >
      <Cell
        stickyHeading
        gridColumn={{ _: "span 1", sm: "span 4", lg: "span 3" }}
      >
        {crafts.map(craft => (
          <p key={craft.id}>
            <Link
              active={selectedCraft.id === craft.id}
              underlined={selectedCraft.id !== craft.id}
              onClick={() => setSelectedCraft(craft)}
            >
              {craft.title}
            </Link>
          </p>
        ))}
      </Cell>
      <Cell gridColumn={{ _: "2 / span 4", sm: "span 6" }}>
        <StaffMemberGrid>
          {filteredStaffMembers.reverse().map((staffMember, i) => (
            <Cell
              key={staffMember.id}
              gridColumn={{ _: "span 4", sm: "span 2" }}
            >
              <Link hoverless to={`/${staffMember.uri}`}>
                <StaffMember
                  third={
                    (i + 1) % 3 == 0 || i == filteredStaffMembers.length - 1
                  }
                >
                  {staffMember.image && staffMember.image[0] && (
                    <img
                      src={staffMember.image[0].url}
                      alt={staffMember.image[0].title}
                    />
                  )}
                  <StaffMemberOverlay>
                    <p>{staffMember.fullName.split(" ")[0]}</p>
                  </StaffMemberOverlay>
                </StaffMember>
              </Link>
            </Cell>
          ))}
        </StaffMemberGrid>
      </Cell>
      <Cell />
    </Block>
  )
}

export default TeamBlock
