import React from 'react'
import { ThemeProvider } from 'styled-components'

import '~styles/fonts.scss'
import GlobalStyle from '~styles/global'
import theme from '~styles/theme'
import useBreakpoint from '~utils/use-breakpoint'

import PageContext from '~components/PageContext'
import FixedMenu from '~components/FixedMenu'
import { navigation } from '~components/MainHeader'

const Layout = ({ children, location }) => {
  const { sm } = useBreakpoint()

  if (sm) {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <FixedMenu navigation={navigation} />
          <PageContext location={location}>
            {children}
          </PageContext>
        </>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <FixedMenu navigation={navigation} />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
